import type { GetServerSidePropsContext } from 'next'
import type { cookies } from 'next/headers'
import { parse } from 'set-cookie-parser'
import Cookie, { ICookie } from 'cookie-universal'
import { v4 as uuid } from 'uuid'
import { IncomingMessage, OutgoingHttpHeader, ServerResponse } from 'http'

import { logger } from '@mc/next-logger'
import { stringToHash } from './stringHash'

import { ANONYMOUS_ID, ANONYMOUS_ID_COOKIE_AGE } from './constants'

const ANON_ID_LOGGING = process?.env?.ANON_ID_LOGGING === 'true'

type Req = GetServerSidePropsContext['req'] | IncomingMessage
type Res = GetServerSidePropsContext['res'] | ServerResponse<IncomingMessage>

/**
 * Returns an anonymous id by:
 * 1. Checking the request cookies, in case it was set in a previous request
 * 2. Checking the response set cookies, in case they were set previously during the request
 * 3. Creating a new id surrounded by double quotes to be compliant with Segment if the previous values were not set
 *
 * @returns {String} The anonymous id without the surrounding quotes
 */
export function getAnonymousId(request: Req, response: Res) {
  const callId = uuid()
  const requestId = request?.headers?.['x-request-id'] || 'no-request-id'
  const connectingIp =
    request?.headers?.['cf-connecting-ip'] || 'no-cf-connecting-ip'
  const userAgent = request?.headers?.['user-agent']
  const userAgentHash = userAgent ? stringToHash(userAgent) : 'no-user-agent'
  const cfRay = request?.headers?.['cf-ray'] || 'no-cf-ray'

  const cookieStore = Cookie(request, response)
  const resSetCookie = response?.getHeaders?.()?.['set-cookie']
  const existingAnonymousId = getAnonymousIdCookie(cookieStore, resSetCookie)
  const anonymousId = existingAnonymousId || generateAnonymousId()

  if (ANON_ID_LOGGING)
    logger.log('[ANON-ID] server getAnonymousId', {
      callId,
      requestId,
      userAgentHash,
      cfRay,
      connectingIp,
      existingAnonymousId: existingAnonymousId || 'no-existing-anonymous-id',
      anonymousId,
    })

  if (!existingAnonymousId) {
    if (ANON_ID_LOGGING)
      logger.log('[ANON-ID] Setting new anonymous ID:', { anonymousId, callId })
    setAnonymousIdCookie(request, response, anonymousId)
  }

  return anonymousId.replace(/"/g, '')
}

export const getAnonymousIdCookie = (
  cookieStore: ICookie | ReturnType<typeof cookies>,
  resSetCookie: OutgoingHttpHeader | undefined,
) => {
  // try to get the anon ID cookie from the request
  const fromReq = cookieStore.get(ANONYMOUS_ID)
  if (fromReq && typeof fromReq === 'string') {
    return fromReq
  }

  // otherwise, try to see if it is on the response (set-cookie)
  if (!resSetCookie) return undefined

  // typescript edge-case for OutgoingHttpHeader type
  // if the cookie is a number then its definitely not a UUID for `ajs_anonymous_id`
  if (typeof resSetCookie === 'number') return undefined

  // parse the set cookie header
  const parsed = parse(resSetCookie)
  if (!parsed || !Array.isArray(parsed) || parsed.length === 0) return undefined
  const firstValue = parsed?.[0]?.value
  if (firstValue) {
    // successfully returning from set-cookie (set in this request)
    return firstValue
  }

  return undefined
}

const setAnonymousIdCookie = (req: Req, res: Res, anonymousId: string) => {
  Cookie(req, res).set(ANONYMOUS_ID, anonymousId, {
    path: '/',
    maxAge: ANONYMOUS_ID_COOKIE_AGE,
  })
}

export const generateAnonymousId = () => `next-gen-${uuid()}`

export * from './constants'
