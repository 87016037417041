import React, { createContext, useContext } from 'react'

import type { Experiments, ExperimentsVariables } from '@mc/experiments'

export type ExperimentsContextType = {
  experiments: Experiments
  experimentsVariables: ExperimentsVariables
}

const ExperimentsContext = createContext<ExperimentsContextType | null>(null)

type ExperimentsProviderProps = {
  value: ExperimentsContextType
  children: React.ReactNode
}

export const ExperimentsProvider = ({
  value,
  children,
}: ExperimentsProviderProps) => (
  <ExperimentsContext.Provider value={value}>
    {children}
  </ExperimentsContext.Provider>
)

export const useExperiments = () => {
  const ctx = useContext(ExperimentsContext)
  if (!ctx) throw new Error('ExperimentsContext requires ExperimentsProvider')
  return ctx
}

export const useBucket = <
  T extends string | null | undefined = Experiments[keyof Experiments],
>(
  featureName: string,
) => {
  const { experiments } = useExperiments()
  return (experiments?.[featureName] ?? undefined) as T | undefined
}

export const useExperimentVariables = (featureName: string) => {
  const { experimentsVariables } = useExperiments()
  return experimentsVariables[featureName] || {}
}
