import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ContentCategory,
  ContentHistoryItem,
  ContentItem,
} from '@mc/persona-api'

import { usePersonaTrack } from '../../tracking'

export const parseCategory = (category?: string) =>
  category ? category?.toLowerCase()?.replace(/ /g, '_') : undefined

export const useLibraryEvents = ({
  instructorSlug,
  conversationId,
}: {
  instructorSlug: string
  conversationId?: string
}) => {
  const { track, trackPage, loaded, isLoggedIn, conversationType } =
    usePersonaTrack()
  const libraryTrackProps = useMemo(
    () => ({
      page: isLoggedIn
        ? 'persona content page (post-login)'
        : 'persona content page (pre-login)',
      instructor_slug: instructorSlug,
      conversation_id: conversationId,
      is_ongoing_conversation: !!conversationId,
      conversation_type: conversationType,
    }),
    [isLoggedIn, instructorSlug, conversationId, conversationType],
  )

  const contentTrackProps = useMemo(
    () => ({
      page: isLoggedIn
        ? 'persona content card (post-login)'
        : 'persona content card (pre-login)',
      instructor_slug: instructorSlug,
      is_ongoing_conversation: !!conversationId,
      conversation_type: conversationType,
    }),
    [instructorSlug, conversationId, isLoggedIn, conversationType],
  )

  const trackLibraryPageViewed = useCallback(
    ({
      topNavName,
      categoryTab,
      contentCount,
    }: {
      topNavName: 'library' | 'history'
      categoryTab?: string
      contentCount: number
    }) => {
      if (loaded) {
        const trackProps = {
          ...libraryTrackProps,
          top_nav_name: topNavName,
          category_tab: parseCategory(categoryTab),
        }
        trackPage('Page Loaded', trackProps)
        track('Persona Viewed', { ...trackProps, content_count: contentCount })
      }
    },
    [track, trackPage, loaded, libraryTrackProps],
  )

  const useLibraryPageLoaded = ({
    activeTab,
    activeCategory,
    isLoading,
    content,
  }: {
    instructorSlug: string
    conversationId?: string
    activeTab: string
    activeCategory?: ContentCategory | null
    isLoading: boolean
    content: ContentHistoryItem[] | ContentItem[]
  }) => {
    const [isPageLoadedTracked, setIsPageLoadedTracked] = useState(false)
    const [currentTab, setCurrentTab] = useState(activeTab)
    const [currentCategory, setCurrentCategory] = useState(activeCategory)

    useEffect(() => {
      let changed = false
      if (!isLoading) {
        if (currentTab !== activeTab) {
          setCurrentTab(activeTab)
          changed = true
        }

        if (currentCategory?.uuid !== activeCategory?.uuid) {
          setCurrentCategory(activeCategory)
          changed = true
        }
        if (changed) {
          setIsPageLoadedTracked(false)
        }
      }
    }, [activeTab, currentTab, activeCategory, currentCategory, isLoading])

    useEffect(() => {
      if (!isLoading && !isPageLoadedTracked && currentCategory && currentTab) {
        trackLibraryPageViewed({
          ...libraryTrackProps,
          topNavName: currentTab as 'library' | 'history',
          categoryTab: currentCategory?.text?.title,
          contentCount: content?.length ?? 0,
        })
        setIsPageLoadedTracked(true)
      }
    }, [
      isLoading,
      currentCategory,
      currentTab,
      content?.length,
      isPageLoadedTracked,
    ])
  }

  const trackContentTabClicked = ({ tab }: { tab: 'library' | 'history' }) => {
    track('Persona Click', {
      ...libraryTrackProps,
      cta: tab,
      location: 'contents-top-nav-tab',
      action: 'navigation clicked',
    })
  }

  const trackContentCategoryClicked = ({
    category,
  }: {
    category?: string | null
  }) => {
    track('Persona Click', {
      ...libraryTrackProps,
      cta: parseCategory(category || 'all'),
      location: 'contents-category-tab',
      action: 'navigation clicked',
    })
  }

  const trackContentBackClicked = () => {
    track('Persona Click', {
      ...libraryTrackProps,
      cta: 'back button',
      location: 'contents-category-tab',
      action: 'navigation clicked',
    })
  }

  const trackContentRowTile = ({
    isClick,
    rowName,
    rowPositionIndex,
    cta,
    tilePositionIndex,
    contentUuid,
    tileTitle,
    tileSubtitle,
    tileLink,
    tileInstructorSlug,
  }: {
    isClick: boolean
    rowName: string
    rowPositionIndex: string
    cta: string
    tilePositionIndex: string
    contentUuid: string
    tileTitle: string
    tileSubtitle?: string
    tileLink: string
    tileInstructorSlug: string
  }) => {
    const { instructor_slug: _, ...rest } = libraryTrackProps
    const trackProps = {
      ...rest,
      row_name: rowName,
      row_position_index: rowPositionIndex,
      cta,
      tile_position_index: tilePositionIndex,
      content_uuid: contentUuid,
      tile_type: 'content card',
      tile_title: tileTitle,
      tile_subtitle: tileSubtitle,
      tile_link: tileLink,
      tile_instructor_slug: tileInstructorSlug,
    }
    if (isClick) {
      track('Persona Row Tile Clicked', trackProps)
    } else {
      track('Persona Row Tile Viewed', trackProps)
    }
  }

  const isRecipePageTracked = useRef(false)
  const trackRecipePageLoaded = useCallback(
    ({
      isRecommendedContent,
      recommendationId,
      contentUuid,
      contentTitle,
    }: {
      isRecommendedContent: boolean
      recommendationId?: string
      contentUuid: string
      contentTitle: string
    }) => {
      if (loaded && !isRecipePageTracked.current) {
        const trackProps = {
          ...contentTrackProps,
          ...(isRecommendedContent && {
            is_recommended_content: isRecommendedContent,
            recommendation_id: recommendationId || '',
          }),
          content_uuid: contentUuid,
          content_title: contentTitle,
        }
        trackPage('Page Loaded', trackProps)
        track('Persona Viewed', trackProps)
        isRecipePageTracked.current = true
      }
    },
    [contentTrackProps, loaded, track, trackPage],
  )

  const trackRecipePageBackButtonClick = ({
    contentUuid,
    contentTitle,
  }: {
    contentUuid: string
    contentTitle: string
  }) => {
    track('Persona Click', {
      ...contentTrackProps,
      cta: 'back button',
      action: 'navigation clicked',
      content_uuid: contentUuid,
      content_title: contentTitle,
    })
  }

  const trackRecipeStartCall = ({
    cta,
    contentUuid,
    contentTitle,
  }: {
    cta: 'sticky start a call button' | 'hero start a call button'
    contentUuid: string
    contentTitle: string
  }) => {
    const { is_ongoing_conversation: _, ...trackProps } = contentTrackProps
    track('Persona Click', {
      ...trackProps,
      cta,
      action: 'call expert clicked',
      content_uuid: contentUuid,
      content_title: contentTitle,
    })
  }

  const trackRecipeStartChat = ({
    cta,
    contentUuid,
    contentTitle,
  }: {
    cta: 'sticky start a chat button' | 'hero start a chat button'
    contentUuid: string
    contentTitle: string
  }) => {
    const { is_ongoing_conversation: _, ...trackProps } = contentTrackProps
    track('Persona Click', {
      ...trackProps,
      cta,
      action: 'chat expert clicked',
      content_uuid: contentUuid,
      content_title: contentTitle,
    })
  }

  const isRecommendationsPageTracked = useRef(false)
  const trackRecommendationsPageLoad = ({
    recommendationIdContents,
    triggerMessageId,
  }: {
    recommendationIdContents: string[]
    triggerMessageId: string
  }) => {
    if (isRecommendationsPageTracked.current) return
    const trackProps = {
      ...contentTrackProps,
      page: 'persona content recommendations page (post-login)',
      recommendation_id_contents: recommendationIdContents,
      content_count: recommendationIdContents.length,
      trigger_message_id: triggerMessageId,
      // TODO: add recommendation_id
      recommendation_id: null,
    }
    trackPage('Page Loaded', trackProps)
    track('Persona Viewed', trackProps)
    isRecommendationsPageTracked.current = true
  }

  const trackRecommendationsRowTile = ({
    index,
    contentUuid,
    tileTitle,
    tileLink,
    isClick,
  }: {
    index: string
    contentUuid: string
    tileTitle: string
    tileLink: string
    isClick: boolean
  }) => {
    track(isClick ? 'Persona Row Tile Clicked' : 'Persona Row Tile Viewed', {
      ...libraryTrackProps,
      page: 'persona content recommendations page (post-login)',
      row_name: 'recommendations',
      row_position_index: 0,
      tile_position_index: index,
      content_uuid: contentUuid,
      tile_type: 'content card',
      tile_title: tileTitle,
      tile_subtitle: null,
      tile_link: tileLink,
      // TODO: add recommendation_id
      recommendation_id: null,
    })
  }

  const trackRecommendationBackButtonClick = () => {
    track('Persona Click', {
      ...libraryTrackProps,
      page: 'persona content recommendations page (post-login)',
      cta: 'close',
      action: 'navigation clicked',
      destination_page: 'persona conversation',
      // TODO: add recommendation_id
      recommendation_id: null,
    })
  }

  return {
    useLibraryPageLoaded,
    trackContentTabClicked,
    trackContentCategoryClicked,
    trackContentBackClicked,
    trackContentRowTile,
    trackRecipePageLoaded,
    trackRecipePageBackButtonClick,
    trackRecipeStartCall,
    trackRecipeStartChat,
    trackRecommendationsPageLoad,
    trackRecommendationsRowTile,
    trackRecommendationBackButtonClick,
  }
}
