import { useEffect } from 'react'
import { useRouter } from 'next/router'

export const usePreviousUrlTracker = () => {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (
      url: string,
      { shallow }: { shallow?: boolean },
    ) => {
      // Only track actual navigation changes, not URL replacements
      // We can detect this by checking if the event is from a shallow update
      // or if the URL is being replaced (which Next.js indicates with shallow=true)
      if (!shallow) {
        sessionStorage.setItem('personaPreviousUrl', url)
      }
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
}

export const PreviousUrlTracker = () => {
  usePreviousUrlTracker()

  return null
}
