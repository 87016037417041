import type { NextApiRequest, NextApiResponse } from 'next'
import { NextRouter } from 'next/router'
import Cookie from 'cookie-universal'
import { ONE_DAY, GEO_COOKIE_KEY, REGION_COOKIE_KEY } from './constants'

export { GEO_COOKIE_KEY, REGION_COOKIE_KEY }

export const initGeoService = (
  req: Pick<NextApiRequest, 'headers'>,
  res: Pick<NextApiResponse, 'getHeaders'>,
  router: Pick<NextRouter, 'query'>,
): { region: string; geo: string } => {
  const cookies = Cookie(req, res)

  const geoDecider = ({
    headerKey,
    queryKey,
    cookieKey,
    fallback,
  }: {
    headerKey: string
    queryKey: string
    cookieKey: string
    fallback: string
  }) => {
    const cookieOverride = cookies.get(cookieKey)
    const queryOverride = router.query?.[queryKey]
    const header = req.headers?.[headerKey]

    if (queryOverride) {
      cookies.set(cookieKey, queryOverride, {
        maxAge: ONE_DAY,
        path: '/',
      })
    }

    return queryOverride || cookieOverride || header || fallback
  }

  const geo = geoDecider({
    queryKey: 'geo',
    cookieKey: GEO_COOKIE_KEY,
    headerKey: 'cf-ipcountry',
    fallback: 'XX',
  })

  const region = geoDecider({
    queryKey: 'region',
    cookieKey: REGION_COOKIE_KEY,
    headerKey: 'region-isocode',
    fallback: 'XX',
  })

  return {
    region,
    geo,
  }
}

export * from './server'
