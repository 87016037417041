import { paths } from '@mc/paths'

import { PageSource, PageSourceType } from '../../adapters/segment/constants'

export const PAGE_SOURCE_FROM_PATH: Record<string, PageSourceType> = {
  [paths.articles]: PageSource.ARTICLES,
  [paths.bogo]: PageSource.HOLIDAY,
  [paths.categories]: PageSource.CATEGORY,
  [paths.checkout]: PageSource.PLANS,
  [paths.classRecommendations]: PageSource.CLASS_RECS_QUIZ,
  [paths.classRecommendationsResults]: PageSource.CLASS_RECS_RESULTS,
  [paths.gift]: PageSource.GIFT,
  [paths.changePlan]: PageSource.PLANS,
  [paths.promotion]: PageSource.PROMOTION_PAGE,
  [paths.root]: PageSource.HOMEPAGE,
}

export const isChapterPage = (pathname: string) =>
  pathname.startsWith(paths.classPage('')) && pathname.includes('/chapters')

export const isClassPage = (pathname: string) =>
  pathname.startsWith(paths.classPage(''))

export const isEpisodePage = (pathname: string) =>
  pathname.startsWith(paths.seriesPage('')) && pathname.includes('/episodes')

export const isSeriesPage = (pathname: string) =>
  pathname.startsWith(paths.seriesPage(''))

// must be checked before sessions homepage due to increased specificity of prefix
export const isSessionsClassPage = (pathname: string) =>
  pathname.startsWith(paths.sessionsClassHome(''))

export const isSessionsHomePage = (pathname: string) =>
  pathname.startsWith(paths.sessions())

export const isArticlePage = (pathname: string) =>
  pathname.startsWith(paths.article(''))

export const isCategoryPage = (pathname: string) =>
  pathname.startsWith(paths.category(''))

export const isProjectsPage = (pathname: string) =>
  pathname.startsWith(paths.projects(''))

export const isSubcategoryPage = (pathname: string) =>
  pathname.startsWith(paths.subcategoryPage(''))

export const isOutcomePage = (pathname: string) =>
  pathname.startsWith(paths.outcomePage(''))

export const isForBusinsessPage = (pathname: string) =>
  pathname.startsWith(paths.enterpriseHomepage)

export const isForBusinessAcceleratorPage = (pathname: string) =>
  pathname.startsWith(paths.enterpriseAccelerator)

export const isForBusinessCollectivePage = (pathname: string) =>
  pathname.startsWith(paths.enterpriseCollective)

export const isCertificatesPage = (pathname: string) =>
  pathname.startsWith(paths.certificates)
