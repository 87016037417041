import React, { createContext, useContext, useState, ReactNode } from 'react'

export interface ConversationFeedbackParams {
  callDuration?: number
  instructorSlug: string
  conversationSource: string
  suggestedPrompt?: string
  error?: string
}

interface ConversationContextProps {
  // State
  lastConversationId: string | null
  lastConversationMedium: 'call' | 'chat' | null
  switchedConversationMedium: boolean
  conversationEnded: boolean
  feedbackParams: ConversationFeedbackParams | null
  // Actions
  initConversation: (conversationId: string, medium: 'call' | 'chat') => void
  endConversation: () => void
  resetConversation: () => void
  setFeedbackParams: (params: ConversationFeedbackParams | null) => void
}

const ConversationContext = createContext<ConversationContextProps | undefined>(
  undefined,
)

export const useConversation = () => {
  const context = useContext(ConversationContext)
  if (!context) {
    throw new Error(
      'useConversation must be used within a ConversationProvider',
    )
  }
  return context
}

interface ConversationProviderProps {
  children: ReactNode
}

export const ConversationProvider = ({
  children,
}: ConversationProviderProps) => {
  // Refers to the conversationId tied to the medium the user was last using
  const [lastConversationId, setLastConversationId] = useState<string | null>(
    null,
  )
  const [lastConversationMedium, setLastConversationMedium] = useState<
    'call' | 'chat' | null
  >(null)
  const [switchedConversationMedium, setSwitchedConversationMedium] =
    useState<boolean>(false)
  const [conversationEnded, setConversationEnded] = useState<boolean>(false)
  const [feedbackParams, setFeedbackParams] =
    useState<ConversationFeedbackParams | null>(null)

  const initConversation = (
    conversationId: string,
    medium: 'call' | 'chat',
  ) => {
    if (conversationEnded) {
      resetConversation()
    } else if (lastConversationId && lastConversationId !== conversationId) {
      setSwitchedConversationMedium(true)
    }
    setLastConversationId(conversationId)
    setLastConversationMedium(medium)
  }

  const endConversation = () => {
    setConversationEnded(true)
  }

  const resetConversation = () => {
    setLastConversationId(null)
    setLastConversationMedium(null)
    setSwitchedConversationMedium(false)
    setConversationEnded(false)
    setFeedbackParams(null)
  }

  return (
    <ConversationContext.Provider
      value={{
        // State
        lastConversationId,
        lastConversationMedium,
        switchedConversationMedium,
        conversationEnded,
        feedbackParams,
        // Actions
        initConversation,
        endConversation,
        setFeedbackParams,
        resetConversation,
      }}
    >
      {children}
    </ConversationContext.Provider>
  )
}
