import { PageSource, PageSourceType } from '../../adapters/segment/constants'
import {
  PAGE_SOURCE_FROM_PATH,
  isCertificatesPage,
  isChapterPage,
  isClassPage,
  isEpisodePage,
  isSeriesPage,
  isSessionsClassPage,
  isSessionsHomePage,
  isArticlePage,
  isCategoryPage,
  isProjectsPage,
  isSubcategoryPage,
  isOutcomePage,
  isForBusinsessPage,
  isForBusinessAcceleratorPage,
  isForBusinessCollectivePage,
} from './utils'

type getPageSourceType = PageSourceType | 'other' | undefined

export const getPageSource = (): getPageSourceType => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const { pathname } = window.location

  if (!pathname) {
    return undefined
  }

  if (isChapterPage(pathname)) {
    return PageSource.LOGGED_OUT_LESSON
  }

  if (isClassPage(pathname)) {
    return PageSource.CLASS_PAGE
  }

  if (isEpisodePage(pathname)) {
    return PageSource.EPISODE_PAGE
  }

  if (isSeriesPage(pathname)) {
    return PageSource.SERIES_PAGE
  }

  if (isSessionsClassPage(pathname)) {
    return PageSource.SESSIONS_CLASS
  }

  if (isSessionsHomePage(pathname)) {
    return PageSource.SESSIONS_HOME
  }

  if (isArticlePage(pathname)) {
    return PageSource.ARTICLE
  }

  if (isCategoryPage(pathname)) {
    return PageSource.CATEGORY
  }

  if (isProjectsPage(pathname)) {
    return PageSource.PROJECTS
  }

  if (isSubcategoryPage(pathname)) {
    return PageSource.SUBCATEGORY
  }

  if (isOutcomePage(pathname)) {
    return PageSource.OUTCOME
  }

  if (isForBusinessAcceleratorPage(pathname)) {
    return PageSource.MAW_ACCELERATOR
  }

  if (isForBusinessCollectivePage(pathname)) {
    return PageSource.MAW_COLLECTIVE
  }

  if (isForBusinsessPage(pathname)) {
    return PageSource.MAW_HOME
  }

  if (isCertificatesPage(pathname)) {
    return PageSource.CERTIFICATES
  }

  return PAGE_SOURCE_FROM_PATH[pathname] || 'other'
}
