import { useRouter } from 'next/router'

export const PAGE_SOURCES = {
  discovery: 'persona landing page',
  bioNoUser: (instructor: string) => `${instructor} bio page (pre-login)`,
  bio: (instructor: string) => `${instructor} bio page (post-login)`,
  call: () => 'persona conversation',
  settings: 'persona settings',
  login: 'persona login',
  'forgot-password': 'reset password',
  library: 'persona library',
  homepage: 'persona discovery (post-login)',
  roleplayHomepage: 'persona roleplay discovery (post-login)',
} as const

export type ExtendedSources = keyof typeof PAGE_SOURCES
export const getPageSource = (source: ExtendedSources, instructor?: string) => {
  const pageSource = PAGE_SOURCES[source as keyof typeof PAGE_SOURCES]
  if (typeof pageSource === 'function' && instructor) {
    return pageSource(instructor)
  }
  return pageSource as string
}

export const useMarketingPageSource = ({
  isLoggedIn,
}: {
  isLoggedIn: boolean
}) => {
  const queryParams = useRouter().query

  const { slug, instructor, page_source: pageSource } = queryParams
  if (!pageSource) {
    return undefined
  }

  let source: ExtendedSources | undefined
  if (pageSource === 'bio') {
    source = isLoggedIn ? 'bio' : 'bioNoUser'
  } else {
    source = pageSource as ExtendedSources
  }

  return getPageSource(
    source as ExtendedSources,
    (slug || instructor) as string,
  )
}
