import Cookies from 'cookie-universal'
import humps from 'humps'
import { AppContext } from 'next/app'
import Stripe from 'stripe'
import { NextApiRequest } from 'next'
import localeCurrency from 'locale-currency'

import {
  CheckoutTypes,
  paths,
  removeSelectedTierCookie,
} from '@mc/persona-client'

import { getServerGeo } from 'libs/server/geo/server'
import { getHeaders } from 'pages/api/getHeaders'

import { memoize } from './memoize'
import { getOrRefreshAccessToken } from './getOrRefreshAccessToken'
import { getUser } from './getUser'

const chooseCurrency = (countryCode: string): string =>
  localeCurrency.getCurrency(countryCode.toUpperCase())?.toLowerCase() || 'usd'

type IntermediateTier = Omit<Stripe.Price, 'billing_scheme' | 'product'> &
  Omit<
    CheckoutTypes.TierBE,
    'internalData' | 'gatewayProducts' | 'displayName'
  > & {
    billing_scheme: 'per_unit'
    product: Stripe.Product
    internalData: {
      displayName: string
    } & CheckoutTypes.TierBE['gatewayProducts']['stripe']
  }

const findPriceAndFormat = (
  prices: Stripe.Price[],
  { displayName, gatewayProducts }: CheckoutTypes.TierBE,
  lookupKeys: string[],
): CheckoutTypes.Tier | null => {
  // Find the first lookupKey that matches a price
  const matchingPrice = lookupKeys
    .map((key) => prices.find((price) => price.lookup_key === key))
    .find((price) => price !== undefined)

  if (!matchingPrice) {
    return null
  }

  const usdPrice =
    prices.find((price) => price.currency === 'usd')?.unit_amount || null

  const product =
    matchingPrice.product &&
    typeof matchingPrice.product === 'object' &&
    !('deleted' in matchingPrice.product)
      ? matchingPrice.product
      : null

  if (!product) {
    return null
  }

  return formatTier({
    ...matchingPrice,
    usdPrice,
    product,
    billing_scheme: 'per_unit',
    internalData: {
      displayName,
      ...gatewayProducts.stripe,
    },
  })
}

export const getPersonaTiers = async (
  ctx: AppContext['ctx'],
): Promise<{ props: { tiers: CheckoutTypes.Tier[] } }> => {
  const accessToken = await getOrRefreshAccessToken({
    req: ctx.req as NextApiRequest,
    res: ctx.res!,
  })
  const user = await getUser(accessToken)

  // Tiers should be only fetched for users without subscription
  if (
    user?.accessibleProducts?.length &&
    !ctx.pathname.includes(paths.personaCheckoutRoot)
  ) {
    return { props: { tiers: [] } }
  }
  const tiersBE = await fetch(
    `${process.env.PERSONA_SERVER_URL}/commerce/plans?gateway=stripe`,
    {
      method: 'GET',
      headers: getHeaders(),
    },
  )
    .then((res) => {
      if (res.status >= 400) {
        throw new Error(`Request failed with status ${res.status}`)
      }
      return res
    })
    .then(async (res) => humps.camelizeKeys(await res.json()))
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching tiers', err)
      return []
    })

  if (tiersBE) {
    try {
      const geo = getServerGeo(ctx)
      const country = geo.geo === 'XX' ? 'US' : geo.geo

      const pricesInGeo = (productIds: CheckoutTypes.TierBE[]) =>
        Promise.all(
          productIds.map(async (tier) => {
            const currency = chooseCurrency(country)
            const geoLookupKey = `${tier.gatewayProducts.stripe.resourceIdentifier}-${currency}-${country.toLowerCase()}`
            const currencyLookupKey = `${tier.gatewayProducts.stripe.resourceIdentifier}-${currency}`
            const defaultLookupKey = `${tier.gatewayProducts.stripe.resourceIdentifier}-usd`
            const priceList = await getStripeProduct(
              tier,
              geoLookupKey,
              currencyLookupKey,
              defaultLookupKey,
            )

            return findPriceAndFormat(priceList.data, tier, [
              geoLookupKey,
              currencyLookupKey,
              defaultLookupKey,
            ])!
          }),
        )

      const tiers = await pricesInGeo(
        (tiersBE as CheckoutTypes.TierBE[]) || [],
      ).then((prices) => prices.filter(Boolean))

      if (!tiers.length) {
        const cookies = Cookies(ctx.req, ctx.res)
        removeSelectedTierCookie(cookies)
      }

      return {
        props: {
          tiers,
        },
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error getting tiers', err)
      return {
        props: {
          tiers: [],
        },
      }
    }
  }
  return { props: { tiers: [] } }
}

const getStripeProduct = memoize(
  (
    tier: CheckoutTypes.TierBE,
    geoLookupKey: string,
    currencyLookupKey: string,
    defaultLookupKey: string,
  ) =>
    new Stripe(process.env.STRIPE_SECRET_KEY as string, {
      apiVersion: '2024-06-20',
    }).prices.list({
      product: tier.gatewayProducts.stripe.resourceIdentifier,
      active: true,
      lookup_keys: [geoLookupKey, currencyLookupKey, defaultLookupKey],
      expand: ['data.product'],
    }),
  (tier, geoLookupKey, currencyLookupKey, defaultLookupKey) =>
    [
      tier.gatewayProducts.stripe.resourceIdentifier,
      geoLookupKey,
      currencyLookupKey,
      defaultLookupKey,
    ].join('-'),
  6 * 60 * 60 * 1000, // 6h
)

const formatTier = (tier: IntermediateTier): CheckoutTypes.Tier => {
  const currency = tier.currency.toUpperCase()
  const locale = 'en-US'

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  })
  const { minimumFractionDigits } = currencyFormatter.resolvedOptions()

  const conversionFactor = 10 ** (minimumFractionDigits as number)
  const amount = (tier.unit_amount ?? 0) / conversionFactor

  const optionsNoDecimals: Intl.NumberFormatOptions = {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  const optionsWithDecimals: Intl.NumberFormatOptions = {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
  }

  const optionsNoDecimalsWithCurrency: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    useGrouping: false,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }

  const optionsWithDecimalsAndCurrency: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    useGrouping: false,
    currencyDisplay: 'symbol',
    minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
  }

  const full = new Intl.NumberFormat(locale, optionsNoDecimals).format(amount)
  const decimals = new Intl.NumberFormat(locale, optionsWithDecimals).format(
    amount,
  )

  const fullWithCurrency = new Intl.NumberFormat(
    locale,
    optionsNoDecimalsWithCurrency,
  ).format(amount)
  const decimalsWithCurrency = new Intl.NumberFormat(
    locale,
    optionsWithDecimalsAndCurrency,
  ).format(amount)

  const monthlyAmount =
    tier.recurring?.interval === 'year' ? amount / 12 : amount
  const fullMonthly = new Intl.NumberFormat(locale, optionsNoDecimals).format(
    monthlyAmount,
  )
  const decimalsMonthly = new Intl.NumberFormat(
    locale,
    optionsWithDecimals,
  ).format(monthlyAmount)
  const fullMonthlyWithCurrency = new Intl.NumberFormat(
    locale,
    optionsNoDecimalsWithCurrency,
  ).format(monthlyAmount)

  const parts = currencyFormatter.formatToParts(0)
  const currencySymbol =
    parts.find((part) => part.type === 'currency')?.value || ''

  const usdAmount = (tier.usdPrice ?? 0) / 100
  const usdFormatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  const decimalsUsd = usdFormatter.format(usdAmount)
  const decimalsUsdMonthly =
    tier.recurring?.interval === 'year'
      ? usdFormatter.format(usdAmount / 12)
      : decimalsUsd

  return {
    ...tier,
    product: {
      ...tier.product,
      default_price:
        typeof tier.product.default_price === 'string'
          ? tier.product.default_price
          : 'unknown',
      tax_code:
        typeof tier.product.tax_code === 'string'
          ? tier.product.tax_code
          : 'unknown',
    },
    recurring: tier.recurring ?? { interval: 'month', interval_count: 1 },
    unit_amount: tier.unit_amount ?? 1,
    unit_amount_decimal: tier.unit_amount_decimal ?? '2',
    internalData: {
      ...tier?.internalData,
      prices: {
        full,
        decimals,
        fullWithCurrency,
        decimalsWithCurrency,
        currencySymbol,
        fullMonthly,
        fullMonthlyWithCurrency,
        decimalsMonthly,
        decimalsUsd,
        decimalsUsdMonthly,
      },
    },
  }
}

export const evaluateIfGetPersonaTiers = ({
  isTotal,
  isPartial,
  path,
}: {
  isTotal: boolean
  isPartial: boolean
  path: string
}) => {
  // Exclude forgot-password pages as it don't use tiers at all
  if (path.includes(paths.personaCustomAuth('forgot-password'))) {
    return false
  }
  if (isTotal) {
    return false
  } else if (isPartial) {
    if (path.includes(paths.personaCheckoutRoot)) {
      return true
    }
    return false
  }
  return true
}
