// In alphabetical order
export const EventTypes = {
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_SETTINGS_CLICK: 'Account Settings Click',
  ACCOUNT_SETTINGS_VIEWED: 'Account Settings Viewed',
  ACTIVATION_PAYMENT_LOCATION: 'Gift Activation Payment Setup Step',
  ADVOCATE_GUEST_PASS: 'Advocate Guest Pass',
  ARTICLES_HOMEPAGE_CLICK: 'Articles Homepage Click',
  ARTICLE_HOMEPAGE_CLICK: 'Article Homepage Click',
  ARTICLE_PAGE_CLICK: 'Article Page Click',
  ARTICLE_PAGE_RECIPE_CLICK: 'Recipe Checkbox Clicked',
  ARTICLE_QUIZ_CLICK: 'Article Quiz Clicked',
  ARTICLE_QUIZ_RESULTS_VIEWED: 'Article Quiz Results Viewed',
  ARTICLE_QUIZ_STARTED: 'Article Quiz Started',
  ARTICLE_UP_NEXT_VIDEO_PLAYED: 'Article Up Next Video Played',
  ARTICLE_VIEWED: 'Viewed Article',
  ASSIGNMENT_COMPLETION: 'Assignment Completed',
  AUTO_LOGIN: 'Auto Login',
  BANNER_CLICK: 'BANNER CLICK',
  BOOKMARK: 'Bookmark',
  BOOKMARKS_PAGE_CLICK: 'Bookmarks Page Click',
  BROWSE_TRAILERS_ADD_CLASS_CLICKED: 'Browse Trailers Add Class Clicked',
  BROWSE_TRAILERS_CATEGORY_CLICKED: 'Browse Trailers Category Clicked',
  BROWSE_TRAILERS_CONTINUE_TO_HOMEPAGE_CLICKED:
    'Browse Trailers Continue To Homepage Clicked',
  BROWSE_TRAILERS_NEXT_TRAILER_CLICKED: 'Browse Trailers Next Trailer Clicked',
  BROWSE_TRAILERS_REMOVE_CLASS_CLICKED: 'Browse Trailers Remove Class Clicked',
  BROWSE_TRAILERS_VIDEO_COMPLETED: 'Browse Trailers Video Completed',
  BROWSE_TRAILERS_VIDEO_PLAYED: 'Browse Trailers Video Played',
  BROWSE_TRAILERS_VIEW_CLICKED: 'Browse Trailers View Clicked',
  CANCEL: 'Cancel',
  CANCELLED_SUBSCRIPTION: 'Cancelled Subscription',
  CANCELLED_SUBSCRIPTION_SUCCESS: 'Cancelled Subscription Success',
  CANCEL_FLOW_CLICK: 'X days left cancel flow click',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  CART: 'Cart',
  CART_EMAIL_CAPTURE: 'Cart email capture',
  CART_FIELD_CLICK: 'Clicked Cart Field',
  CATALOG_PAGE_CLICK: 'Catalog Page Click',
  CATEGORY_LANDING_VIEW: 'Category Landing',
  CATEGORY_PAGE_CLICK: 'Category Page Click',
  CDP_HERO_CTA_CLICKED: 'Class Details Page Hero CTA Clicked',
  CHECKOUT: 'Checkout',
  CHECKOUT_CLICK: 'Checkout Click',
  CLASS_ENROLLMENT_SUBSCRIPTION: 'Class Enrollment (Subscription)',
  CLASS_OVERVIEW_CLICK: 'Class Overview Click',
  CLASS_RECOMMENDATIONS_PAGE_VIEWED: 'Class Recommendations Page Viewed',
  CLICKED_BILLING_UPDATE: 'Clicked Billing Update',
  CLICKED_SUBSCRIPTION_OPT_OUT_STEP:
    'Clicked Gift Activation Subscription Opt Out Step',
  CLOSED_NPS: 'NPS Closed',
  COMMENT: 'Comment',
  COMMENT_ACTION: 'Comment Action',
  COMMUNITY_CLICKED: 'Community Clicked',
  COMPLETED_ACTIVATION_SETUP: 'Completed Gift Activation Payment Setup Step',
  COMPLETED_ORDER: 'Completed Order',
  CONFIRM_BILLING_UPDATE: 'Confirm Billing Update',
  CONTENT_ASSIGNMENTS_SHARE_BUTTON_CLICKED:
    'Content Assignments Share Button Clicked',
  CONTENT_ASSIGNMENTS_SHARE_FAILED: 'Content Assignments Share Failed',
  CONTENT_ASSIGNMENTS_SHARE_MODAL_VIEWED:
    'Content Assignments Share Modal Viewed',
  CONTENT_ASSIGNMENTS_SHARE_SUCCESSFULL: 'Content Assignments Share Successful',
  COURSE_BANNER_CLICK: 'Course Banner Click',
  COURSE_MARKETING: 'Course Marketing',
  COURSE_MARKETING_CLICK: 'Course Marketing Click',
  COURSE_MARKETING_PAGE_ROW_VIEWED: 'Course Marketing Page Row Viewed',
  CYP_MODAL_SHOW: 'Complete your profile show',
  CYP_MODAL_SUBMIT: 'Complete your profile submit',
  DISCOVER_QUIZ_CLICK: 'Discover Quiz Click',
  DISCOVER_QUIZ_VIEWED: 'Discover Quiz Viewed',
  EMAIL_CAPTURED: 'Email Captured',
  END_SCREEN_CLICK: 'End Screen Click',
  END_SCREEN_STAR_RATING: 'End Screen Star Rating',
  END_SCREEN_VIEW: 'End Screen View',
  ENTERPRISE_ACCELERATOR: 'Enterprise Accelerator',
  ENTERPRISE_ADMIN: 'Enterprise Admin',
  ENTERPRISE_LANDING_PAGE: 'Enterprise Landing Page',
  ENTERPRISE_CART: 'Enterprise Cart',
  ENTERPRISE_COLLECTIVE: 'Enterprise Collective',
  ENTERPRISE_SALES_LEAD_FAIL: 'Enterprise Sales Leads Fail',
  ENTERPRISE_SALES_LEAD_SUCCESS: 'Enterprise Sales Leads Success',
  EPISODE_PAGE_CLICK: 'Episode Page Click',
  EPISODE_PAGE_ROW_VIEWED: 'Episode Page Row Viewed',
  EXPERIMENT_BUCKETED: 'Experiment Bucketed',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  EXPLAINER_PAGE_CLICK: 'Explainer Page Click',
  EXPLORE_CLASS_FROM_PLAYER: 'Explore Full Class Click From Player',
  FACEBOOK_LOGIN_CLICKED: 'Facebook Login Clicked',
  FAQ_VIEWED: 'FAQ Viewed',
  FIELD_ENTRY_WARNING_CTA_CLICKED: 'Field Entry Warning CTA Clicked',
  FIELD_ENTRY_WARNING_VIEWED: 'Field Entry Warning Viewed',
  FOOTER_EMAIL_CAPTURE: 'Footer Email Capture',
  FOR_BUSINESSES: 'For Businesses',
  FOR_BUSINESSES_LEAD_FORM_FAIL: 'For Businesses Lead Form Fail',
  FOR_BUSINESSES_LEAD_FORM_SUBMITTED: 'For Businesses Lead Form Submitted',
  FOR_BUSINESSES_LEAD_FORM_SUCCESS: 'For Businesses Lead Form Success',
  FREE_TRIAL_BANNER_CLICK: 'Free Trial Banner Click',
  GIFTING_CLASS_OPTIONS: 'Gifting Class Options',
  GIFT_CATEGORY_TILE_CLICK: 'Gift Category Tile Click',
  GIFT_PAGE_CLICK: 'Gift Page Click',
  HERO_CC_TOGGLED: 'Hero CC Toggled',
  HERO_CDP_CC_TOGGLED: 'CDP Hero CC Toggled',
  HERO_CDP_MUTE_TOGGLED: 'CDP Hero Mute Toggled',
  HERO_CDP_PLAY_PAUSE_TOGGLED: 'CDP Hero Play Pause Toggled',
  HAMBURGER_MENU_CLICKED: 'Hamburger Menu Clicked',
  HERO_CLASS_EXPLORED: 'Hero Class Explored',
  HERO_MUTE_TOGGLED: 'Hero Mute Toggled',
  HERO_NEXT_VIDEO_CLICKED: 'Hero Next Video Clicked',
  HERO_PLAY_PAUSE_TOGGLED: 'Hero Play Pause Toggled',
  HERO_PREVIOUS_VIDEO_CLICKED: 'Hero Previous Button Clicked',
  HOLIDAY: 'Holiday',
  HOLIDAY_PAGE_CLICK: 'Holiday Page Click',
  HOME: 'Home',
  HOMEPAGE_CLICK: 'Home Page Click',
  HOMEPAGE_HERO_VIEWED: 'Home Page Hero Viewed',
  HOVER_PLAY_VIDEO_STARTED: 'Hover Play Video Started',
  LABS_MODAL_CLICKED: 'Labs Modal Clicked',
  LABS_MODAL_VIEWED: 'Labs Modal Viewed',
  LEARNING_PATH_CLICKED: 'Learning Path Clicked',
  LEARNING_PATH_VIEWED: 'Learning Path Viewed',
  LESSON: 'Lesson',
  LESSON_COMPLETION: 'Lesson Completion',
  LESSON_PLAN: 'Lesson Plan',
  LESSON_PLAYED: 'Lesson Played',
  LESSON_RATING_GIVEN: 'Lesson Rated',
  LESSON_RESOURCE_CLICK: 'Clicked Lesson Resource',
  LESSON_RESOURCE_VIEW: 'Lesson Resource',
  LESSON_VIEW_CLICK: 'Lesson View Click',
  LESSON_VIEW_SCROLLED: 'Lesson View Scrolled',
  LIBRARY_CLICK: 'Library Click',
  LIBRARY_PAGE_CLICK: 'Library Page Click',
  LOCALE_UPDATED: 'Preferred Language Changed',
  LOCATION_COLLECTION_MODAL_COMPLETED: 'Location collection modal completed',
  LOCATION_COLLECTION_MODAL_EXITED: 'Location collection modal exited',
  LOCATION_COLLECTION_MODAL_VIEWED: 'Location collection modal viewed',
  LOCKED_COURSE_CLICK: 'Locked Course Click',
  LOGGED_OUT_LESSON_PAGE_VIEWED: 'Viewed Logged out Lesson Page',
  LOG_IN_MODAL_CTA_CLICKED: 'Log In Modal CTA Clicked',
  MATURITY_DISCLAIMER_ACCEPTED: 'Maturity disclaimer accepted',
  MATURITY_DISCLAIMER_CARD_VIEWED: 'Maturity disclaimer viewed',
  MATURITY_DISCLAIMER_REJECTED: 'Maturity disclaimer rejected',
  MATURITY_TOGGLED: 'Maturity toggled',
  MAW_LEAD_SUBMITTED: 'MAW Lead Submitted',
  MEDIA_CONTENT_PLAYING: 'Media Content Playing',
  MEDIA_CONTENT_STARTED: 'Media Content Started',
  MODAL_CLICKED: 'Modal Clicked',
  MODAL_VIEWED: 'Modal Viewed',
  MODULE_COMPLETION: 'Module Completion',
  MY_CLASSES_PAGE_CLICKED: 'My Classes All Page Class Clicked',
  NEXT_LESSON: 'Next Lesson',
  NEXT_SECTION_CLICKED: "What's Next Section Clicked",
  NO_THANKS_CLICKED: 'No thanks, Just Activate Gift Clicked',
  OUTCOME_PAGE_CLICK: 'Outcome Page Click',
  OUTCOME_PAGE_ROW_VIEWED: 'Outcome Page Row Viewed',
  PACKAGES_MODAL_CLICKED: 'Packages Modal Clicked',
  PACKAGES_MODAL_VIEWED: 'Packages Modal Viewed',
  PAUSE_SCREEN_CLICK: 'Pause Screen Clicked',
  PAUSE_SCREEN_EVENT: 'Pause Screen Event',
  PAUSE_SCREEN_VIEW: 'Pause Screen Viewed',
  PAYMENT_METHOD_MODAL: 'Payment Method Modal',
  PLANS_PAGE_CLICK: 'Plans Page Click',
  PLANS_PAGE_VIEWED_GOLD_STANDARD: 'Plans Page Viewed',
  PLANS_VIEWED: 'Plans View',
  PLAN_CHANGE_ABORTED: 'Plan Change Aborted',
  PLAN_CHANGE_COMPLETED: 'Plan Change Completed',
  PLAYBAR_CLICKED: 'Playbar Clicked',
  PLAYLIST_CLICK: 'Playlist Clicked',
  POST_CREATION: 'Post Creation',
  PREPAID_CARD_DECLINED: 'Prepaid Card Declined',
  PRODUCT_LIST_VIEWED_GOLD_STANDARD: 'Product List Viewed',
  PRODUCT_VIEWED_GOLD_STANDARD: 'Product Viewed',
  PROMO_APPLY_CLICKED: 'Coupon Applied',
  PROMO_MODAL_CTA_CLICKED: 'Promo Modal CTA Clicked',
  PROMO_MODAL_TERMS_CLICKED: 'Promo Modal Offer Terms Clicked',
  PROMO_MODAL_VIEWED: 'Promo Modal Viewed',
  PURCHASED_CLASS: 'Purchased Class',
  PURCHASE_CLICK: 'Purchase Click',
  PURCHASE_OPTIONS_CLICK: 'Purchase Options Click',
  QUIZ_PAGE_VIEWED: 'Quiz Page Viewed',
  QUIZ_RESULTS_PAGE_CLICK: 'Quiz Results Page Click',
  QUIZ_RESULTS_PAGE_ROW_VIEWED: 'Quiz Results Page Row Viewed',
  REACTIVATE_SUBSCRIPTION_CLICK: 'Reactivate Subscription Click',
  RECIPIENT_GUEST_PASS: 'Recipient Exclusive Guest Pass',
  RECIPIENT_GUEST_PASS_CLICK: 'Recipient Exclusive Guest Pass Click',
  REFUND: 'Self-Serve Refund',
  REGISTRATION_SUCCESS: 'Cart Registration Successful',
  REMOVE_BOOKMARK: 'Remove Bookmark',
  REMOVE_USER_COURSE: 'My Classes Class Removed',
  SEGMENT_LOADED: 'Segment Loaded',
  SESSION_COURSE_MARKETING_PAGE_CLICK: 'Session Course Marketing Page Click',
  SETTING_PAGE_LINK_CLICKED: 'Settings Page Device Link Clicked',
  SEVEN_DAY_LESSON_PLAN_CLICK: '7 Day Lesson Plan Click',
  SHARE_MODAL_VIEWED: 'Share Modal Viewed',
  SIGNED_IN: 'Signed In',
  SIGNED_UP: 'Signed Up',
  STUDENT_HOME_CONTINUE_LESSON_CLICKED: 'Student Home Continue Lesson Clicked',
  STUDENT_HOME_CONTINUE_LESSON_VIEWED: 'Student Home Continue Lesson Viewed',
  STUDENT_HOME_LAUNCH_BANNER_CLICKED: 'Student Home Launch Banner Clicked',
  STUDENT_HOME_LAUNCH_BANNER_VIEWED: 'Student Home Launch Banner Viewed',
  STUDENT_HOME_MY_COURSE_CLICKED: 'Student Home My Course Clicked',
  STUDENT_HOME_MY_COURSE_VIEWED: 'Student Home My Course Viewed',
  STUDENT_HOME_MY_COURSE_VIEW_ALL_CLICKED:
    'Student Home My Course View All Clicked',
  STUDENT_HOME_PLAYLIST_VIEWED: 'Student Home Playlist Viewed',
  SUBCHAPTER_CHANGE: 'Subchapter Change',
  SUBCATEGORY_CLICK: 'Subcategory Click',
  SUBCATEGORY_PAGE_CLICK: 'Subcategory Page Click',
  SUBCATEGORY_PAGE_ROW_VIEWED: 'Subcategory Page Row Viewed',
  SUBMIT_NPS_CATEGORY: 'NPS Category Given',
  SUBMIT_NPS_COMMENT: 'NPS Feedback Given',
  SUBMIT_NPS_SCORE: 'NPS Rating Given',
  SUCCESSFUL_SUBSCRIPTION: 'Successful Subscription upon Gift Activation',
  SWITCH_LESSON: 'Switch Lesson',
  TOOLTIP_VIEWED: 'Tooltip viewed',
  UPDATE_PAYMENT_METHOD_CLICK: 'Update Payment Method Click',
  VALUE_PROP_VIEWED: 'Value Prop Viewed',
  VIDEO_CONTENT_WATCHED: 'Video Content Watched',
  VIEWED_ACTIVATION_SETUP: 'Viewed Gift Activation Payment Setup Step',
  VIEWED_ARTICLES_HOMEPAGE: 'Viewed Articles Homepage',
  VIEWED_BOOKMARKS_PAGE: 'Viewed Bookmarks Page',
  VIEWED_CANCEL_FEATURE_LIST: 'Viewed Cancel Feature List',
  VIEWED_NPS_SURVEY: 'NPS Survey Viewed',
  WATCH_NOW: 'watch now',
  WORKBOOK_CLICK: 'Workbook Click',
  WQ_SHOW: 'WQ show',
  WQ_SUBMIT: 'WQ submit',
} as const

export const Locations = {
  AAP_UPSELL: 'aap_upsell',
  ACTIVATION_PAYMENT_LOCATION: 'gift-activation-payment-setup-step',
  BANNER: 'banner',
  BODY: 'body',
  BOGO: 'bogo',
  CLASS_ANNOUNCEMENTS: 'class-announcements',
  COMMUNITY: 'community',
  COURSE_MARKETING: 'course-marketing',
  EMAIL_RECIPE_CARD: 'email_recipe_card',
  EMPTY: '',
  END_SCREEN: 'end-screen',
  ENROLLED_COURSES: 'enrolled-course-banner',
  FAQ_SECTION: 'faq-section',
  FOOTER_BANNER: 'footer_banner',
  HEADER: 'header',
  HERO: 'hero',
  INCENTIVES: 'incentives',
  INSTRUCTOR_TILES: 'instructor-tiles',
  LEARN_MORE_CTA: 'learn-more',
  LESSON_PAGE: 'lesson-page',
  LESSON_PAGE_STICKY: 'lesson-page-sticky',
  LESSON_PAGE_TRAILER: 'lesson-page-trailer',
  LESSON_PLAN: 'lesson-plan',
  LESSON_THUMBNAIL: 'lesson-thumbnail',
  LOG_IN_MODAL: 'log_in_modal',
  NAVIGATION: 'navigation',
  OTHER_CLASSES: 'other-classes-banner',
  PROMOTION_BANNER: 'promotion-banner',
  PROMOTION_BANNER_SC: 'promotion-banner-single-class',
  PROMOTION_FOOTER: 'promotion-footer',
  PROMOTION_HERO: 'promotion-hero',
  PROMOTION_MODAL: 'promotion-modal',
  PROMOTION_TOOLTIP: 'promotion-tooltip',
  QUIZ_MODULE: 'quiz-module',
  // pen-4698_v1
  QUIZ_RESULTS_MODAL: 'quiz-results-modal',
  RECIPE_RATING: 'recipe_rating',
  RECOMMENDED_SECTION: 'recommended-section',
  RESOURCES: 'class-resources',
  SELF_SERVE: 'self_serve',
  SEO_DEVICE_LOCKUP: 'seo_device_lockup_ad',
  SKILLS_BANNER: 'skills banner',
  STICKY: 'sticky',
  TILE: 'tile',
  VIDEO: 'video',
  VIDEO_CAROUSEL: 'video-carousel',
  VIDEO_CTA: 'video-cta',
  VIDEO_PLAYER_HEADER: 'video_player_header',
  VIDEO_PLAYER_PAGE: 'video_player_page',
  VIDEO_PLAYER_PAUSE_SCREEN: 'video_player_pause_screen',
  VIDEO_TRAILER: 'video-trailer',
  WHAT_YOU_WILL_GET: 'what_you_will_get',
  UTILITY_NAVIGATION: 'utility-navigation',
} as const

export const Actions = {
  ANNUAL_PASS: 'annual-pass',
  BLOG: 'blog',
  BROWSE_CLASSES: 'browse-classes',
  CHANGE_PLAN: 'change_plan',
  CLASS_CTA: 'class-announcements-cta',
  CLOSE: 'close',
  COMMENT: 'comment',
  COMMENT_REPLY: 'reply',
  COMMUNITY: 'community',
  COMPLETE_MODULE: 'complete-module',
  DISMISS: 'dismiss',
  DOWNLOAD_PDF: 'download-pdf',
  EXPAND_LESSON: 'expand-lesson-plan',
  FAQ: 'faq',
  GIFT: 'gift',
  HOVER: 'hover',
  KEEP_EXPLORING: 'keep exploring',
  LEARN_MORE_DUO: 'learn_more_duo',
  LEARN_MORE_FAMILY: 'learn_more_family',
  LESSON_PLAN: 'lesson-plan',
  LIKE: 'like',
  PLAY_LESSON: 'play-lesson',
  PLAY_SELECT: 'play-select',
  PRIMARY: 'primary',
  PROMO_CTA: 'promo-cta',
  SHOW_LESSONS: 'show-lessons',
  SIGN_UP: 'sign-up',
  SIGN_UP_CTA: 'Sign Up',
  SUBMIT: 'submit',
  TAKE_SURVEY: 'take-survey',
  TILE: 'select-tile',
  TRAILER: 'play-trailer',
  UNLIKE: 'unlike',
  UPGRADE: 'upgrade',
  VIEW_ASSIGNMENT: 'view-assignment',
  VIEW_DETAILS: 'view-details',
  VISIT_THE_HUB: 'visit-the-hub',
  WORKBOOK: 'workbook',
} as const

export const Pages = {
  BROWSE_TRAILERS_PAGE: 'Browse Trailers Page Viewed',
  CAMP_EXPLAINER_PAGE: 'Camp Explainer Page',
  CM_PAGE: 'cm_page',
  HOME_PAGE: 'home_page',
  MY_CLASSES_PAGE: 'My Classes All Page Viewed',
  PROMOTION_PAGE: 'promotion-page',
  SESSIONS_HUB_PAGE: 'Session Hub Page',
  SESSION_COURSE_MARKETING_PAGE: 'Session Course Marketing Page',
} as const

export const Type = {
  ASSIGNMENT: 'assignment',
  BOOKMARK_SUBCHAPTER: 'bookmark_subchapter',
  COOKBOOK: 'cookbook',
  OTHER_RESOURCE: 'other-resource',
  PROFILE_TOOLTIP: 'profile_tooltip',
  PROMOTION: 'promotion',
  RECIPE: 'recipe',
  VIDEO_BOOKMARK: 'video_bookmark',
  WORKBOOK: 'workbook',
} as const

export const EnterpriseGrowthEvents = {
  EVENTS: {
    MAW_HOME_PAGE_CLICK: 'MAW Home Page Click',
  },
} as const

export const EnterpriseAdminEvents = {
  ACTIONS: {
    ADD_SEATS: 'add_seats',
    DELETE_SEAT: 'delete_seat',
    DOWNLOAD: 'download',
    DOWNLOAD_SELECTION: 'download_selection',
    INTERVAL_SELECTION: 'interval_selection',
    INVITE_MEMBERS: 'invite_members',
    INVITE_SENT: 'invite_sent',
    PROGRESS_SELECTION: 'progress_selection',
    RANGE_SELECTION: 'range_selection',
    REMIND_ALL: 'remind_all',
    REMIND_SEAT: 'remind_seat',
    SEND_REMINDER_ALL: 'send_reminder_all',
    SEND_REMINDER_SEAT: 'send_reminder_seat',
  },
  EVENTS: {
    ADMIN_REPORT_ACTION_FAILURE: 'Admin Report Action Failed',
    ADMIN_REPORT_ACTION_SUCCESS: 'Admin Report Action Succeeded',
    ADMIN_REPORT_BUTTON_CLICK: 'Clicked Admin Report Button',
    ADMIN_REPORT_ERROR_VIEWED: 'Admin Report Data Error Viewed',
  },
} as const

export const EnterpriseCartEvents = {
  ACTIONS: {
    BACK: 'Back',
    CLOSE: 'Close',
    CONTACT_SALES: 'Contact sales',
    GO_BACK: 'Go Back',
  },
  EVENTS: {
    CHECKOUT_STARTED: 'Enterprise Checkout Started',
    CONTACT_SALES: 'Contact Sales Clicked',
    CREATE_ACCOUNT_CTA_CLICKED: 'Enterprise Create Account CTA Clicked',
    DISTRIBUTION_CAPTURED: 'Enterprise Distribution Captured',
    EMAIL_CAPTURED: 'Enterprise Cart Email Captured',
    FLOW_CTA_CLICKED: 'Self Serve Flow CTA Clicked',
    INFO_FIELD_CAPTURED: 'Self Serve Info Field Captured',
    LEAD_FORM_SUBMITTED: 'For Businesses Lead Form Submitted',
    MODAL_CLICKED: 'Modal Clicked',
    MODAL_VIEWED: 'Modal Viewed',
    ORDER_COMPLETED: 'Enterprise Order Completed',
    PAGE_LOAD: 'Self Serve: Organization Info',
    PRICING_CALCULATED: 'Enterprise Pricing Calculated',
    PRICING_ERROR_LOGGED: 'Enterprise Pricing Error Logged',
    PRICING_STARTED: 'Enterprise Pricing Started',
    SELF_SERVE_HUBSPOT_FORM_FAILED: 'Self Serve Hubspot Form Failed',
    SELF_SERVE_HUBSPOT_FORM_SUBMITTED: 'Self Serve Hubspot Form Submitted',
    SELF_SERVE_HUBSPOT_FORM_SUCCESS: 'Self Serve Hubspot Form Success',
    SELF_SERVE_STARTED: 'Enterprise Self Serve Started',
    USE_CASE_CAPTURED: 'Enterprise Use Case Captured',
    PAYMENT_OPTION_CLICKED: 'Enterprise Payment Option Clicked',
    PAYMENT_DECLINED: 'Enterprise Payment Declined',
    PAYMENT_SUBMITTED: 'Enterprise Payment Submitted',
    SHOW_PAYMENT_DETAILS: 'Enterprise Show Payment Details - ACH Clicked',
    INVOICE_DOWNLOADED: 'Enterprise Invoice Downloaded',
    PAYMENT_REQUIRES_ACTION: 'Enterprise Payment Requires Action',
    PAYMENT_AUTHENTICATION_FAILED: 'Enterprise Payment Authentication Failed',
  },
  LOCATIONS: {
    FOOTER: 'Footer',
    QUANTITY_EXCEEDED: 'quantity_exceeded_message',
  },
  PAGE_SOURCES: {
    CALCULATOR: 'Self Serve: Pricing Calculator',
    DISTRIBUTION: 'Self Serve: Distribution',
    ORGANIZATION_INFO: 'Self Serve: Organization Info',
    PAYMENT: 'Self Serve: Payment',
    PAYMENT_COMPLETE: 'Self Serve: Payment Complete',
    USE_CASE: 'Self Serve: Use Case',
    INVALID_LINK: 'Self Serve: Invalid Link',
    WIRE_TRANSFER_CONFIRMED: 'Self Serve: ACH Bank Information',
  },
} as const

export const PartnershipsEvents = {
  EVENTS: {
    ACTIVATION_CREATE_ACCOUNT_STARTED:
      'Partner Activation Create Account Started',
    ACTIVATION_DISQUALIFIED_ERROR: 'Partner Disqualified Error',
    ACTIVATION_INVALID: 'Partner Activation Invalid',
    ACTIVATION_PAGE_VIEWED: 'Partner Activation Page Viewed',
    ACTIVATION_QUALIFIED_ERROR: 'Partner Qualified Error',
    ACTIVATION_REFUNDABLE: 'Partner Activation Refundable',
    ACTIVATION_RESELLER_LINK_CLICKED:
      'Partner Activation Reseller Link Clicked',
    ACTIVATION_STARTED: 'Partner Activation Started',
    ACTIVATION_SUCCESS: 'Partner Activation Successful',
    SETTINGS_CANCELLATION_RESELLER_LINK_CLICKED:
      'Partner Settings Cancellation Reseller Link Clicked',
    REVOLUT: {
      ACTIVATION_SCREEN_VIEWED: 'Partnership Activation Revolut Screen Viewed',
      ACTIVATION_SCREEN_CTA_CLICKED:
        'Partnership Activation Revolut CTA clicked',
    },
  },
} as const

export const UpgradeSubscriptionEvents = {
  EVENTS: {
    IMPLICIT_UPGRADE_SUBSCRIPTION_ACTIVATION_ERROR_CTA_CLICK:
      'Customer Implicit Upgrade Subscription Activation Error CTA Click',
    IMPLICIT_UPGRADE_SUBSCRIPTION_ACTIVATION_ERROR_VIEWED:
      'Customer Implicit Upgrade Subscription Activation Error Viewed',
    IMPLICIT_UPGRADE_SUBSCRIPTION_MODAL_CTA_CLICK:
      'Customer Implicit Upgrade Subscription Modal CTA Click',
    IMPLICIT_UPGRADE_SUBSCRIPTION_MODAL_VIEWED:
      'Customer Implicit Upgrade Subscription Modal Viewed',
  },
} as const

export const NavigationEvents = {
  ACTIONS: {
    ARTICLES: 'articles',
    EXPLORE_SKILL_CATEGORIES: 'Explore Skill Categories',
    EXPLORE_SKILL_CATEGORIES_ITEM: (ctaCopy: string) =>
      `Explore Skill Categories: ${ctaCopy}`,
    HOME: 'Home',
    LABS: 'Labs',
    LESSON_SWITCHER: 'Lesson Switcher',
    MY_CLASSES: 'My Classes',
    PLAYLIST: 'Playlist',
    PROFILE: 'Profile',
  },
  CATEGORIES: {
    NAVBAR: 'home-nav-header',
  },
  EVENTS: {
    COURSE_MARKETING_CLICK: 'Course Marketing Click',
    NAVIGATION_CLICKED: 'Navigation Clicked',
    PLANS_PAGE_CLICK: 'View Plans Click',
  },
  LEVELS: {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TERTIARY: 'tertiary',
  },
  PAGE_SOURCES: {
    NAVIGATION: 'Navigation',
  },
} as const

export const PageLoadEvents = {
  PLANS: 'Plans Selection',
  PLANS_POST_PAYWALL: 'Plans Selection Post Paywall',
} as const

export const PageViewEvents: Record<string, { name: string; type?: string }> = {
  'accounts#edit': { name: 'Account Settings', type: 'account_settings' },
  'articles#category': {
    name: 'Article Category',
    type: 'article_category',
  },
  'articles#index': {
    name: 'Article Home',
    type: 'article_home',
  },
  'articles#show': {
    name: 'Article Page',
    type: 'article_page',
  },
  'carts#show': { name: 'Cart', type: 'cart' },
  'categories#index': { name: 'Category', type: 'category' },
  'categories#show': { name: 'Category', type: 'category' },
  'chapters#watch': { name: 'Lesson', type: 'lesson_video' },
  'cm_mobile_pages#show': {
    name: 'Course Marketing',
    type: 'cm_mobile_page',
  },
  'courses#show': { name: 'Course Marketing', type: 'cm' },
  'courses#show_enrolled': { name: 'Class Overview', type: 'cm' },
  'gifting_landing_pages#show': {
    name: 'Gifting Landing',
    type: 'gifting_landing',
  },
  'page#bookmarks': { name: 'Bookmarks', type: 'bookmarks' },
  'pages#homepage': {
    name: 'Viewed Student Home Page',
    type: 'student_homepage',
  },
  'pages#promotion': { name: 'Promotion', type: 'promotion' },
  'pages#root': { name: 'Home', type: 'home' },
  'students#show': { name: 'User Profile' },
}

export const PlaylistEvents = {
  EVENTS: {
    PLAYLIST_COMPLETED: 'Playlist Completed',
    PLAYLIST_EXPLORE_CLASS_CLICKED: 'Playlist Explore Class Clicked',
    PLAYLIST_RATED: 'Playlist Rated',
    PLAYLIST_STARTED: 'Playlist Started',
    PLAYLIST_VIDEO_PLAYBACK_STARTED: 'Playlist Video Playback Started',
    PLAYLIST_WATCH_LESSON_CLICKED: 'Playlist Watch Full Lesson Clicked',
  },
  PAGES: {
    PLAYLIST_PAGE: 'Playlist Home',
    PLAYLIST_VIDEO: 'Playlist Video',
  },
} as const

export const StudentHomeEvents = {
  STUDENT_HOME_CLASSES_VIEWED: 'Student Home Classes Viewed',
  STUDENT_HOME_CLASS_CLICKED: 'Student Home Class Clicked',
  STUDENT_HOME_CLASS_HOVERED: 'Student Home Class Hovered',
  STUDENT_HOME_PROMO_CLICKED: 'Student Home Promo Clicked',
  STUDENT_HOME_PROMO_VIEWED: 'Student Home Promo Viewed',
} as const

export const ContentRowEvents = {
  ROW_BUTTON_CLICKED: 'Row Button Clicked',
  ROW_HIDE: 'Row Hide',
  ROW_SCROLLED: 'Row Scrolled',
  ROW_TILE_CLICKED: 'Row Tile Clicked',
  ROW_TILE_VIEWED: 'Row Tile Viewed',
  ROW_VIEWED: 'Row Viewed',
} as const

export const PageSource = {
  ARTICLE: 'Article',
  ARTICLES: 'Articles',
  CATEGORIES: 'Categories',
  CATEGORY: 'Category',
  CERTIFICATES: 'Certificates',
  CLASS_PAGE: 'class_page',
  CLASS_RECS_QUIZ: 'Find My Classes Quiz',
  CLASS_RECS_RESULTS: 'Find My Classes Results',
  DISCOVERY: 'Discovery',
  EPISODE_PAGE: 'Episode',
  FOR_YOU: 'for_you',
  GIFT: 'Gift',
  HOLIDAY: 'Holiday',
  HOME: 'Home',
  HOMEPAGE: 'homepage',
  HOW_TO: 'How To',
  INBOX: 'inbox',
  LIBRARY: 'library',
  LOG_IN: 'Login',
  LOGGED_OUT_CLASS: 'Logged out class',
  LOGGED_OUT_LESSON: 'Logged out lesson',
  MAW_HOME: 'For Businesses',
  MAW_ACCELERATOR: 'For Businesses Accelerator',
  MAW_COLLECTIVE: 'For Businesses Collective',
  MAW_PURCHASE: 'For Businesses: Lead Form',
  MAW_SKILL: 'For Businesses: Skill Page',
  MY_PROGRESS: 'my_progress',
  OUTCOME: 'Outcome',
  PLANS: 'Plans',
  PLAYLISTS: 'playlists',
  PROJECTS: 'Projects Explainer Page',
  PROMO_BANNER: 'Promo Banner',
  PROMOTION_PAGE: 'promotion-page',
  SERIES_PAGE: 'Series',
  SESSIONS_CLASS: 'sessions_page',
  SESSIONS_HOME: 'sessions_home',
  SUBCATEGORY: 'Subcategory',
  VIDEO_PAGE: 'video_page',
} as const

export type PageSourceType =
  | (typeof PageSource)[keyof typeof PageSource]
  | `${typeof PageSource.HOW_TO}:${string}`

export const NavigationModalEvents = {
  MODAL_CLOSED: 'Simplified Navigation Explanation Closed',
  MODAL_VIEWED: 'Simplified Navigation Explanation Modal Viewed',
  NEXT_CLICKED: 'Simplified Navigation Explanation Next Clicked',
} as const

export const VideoPlayerPageEvents = {
  VIDEO_PLAYER_SIDEBAR_CLICKED: 'Video Player Sidebar Clicked',
  VIDEO_PLAYER_SIDEBAR_VIEWED: 'Video Player Sidebar Viewed',
} as const

export const ContentTypes = {
  COURSE_CHAPTER: 'Course Chapter',
} as const

export const Product = {
  MASTERCAMP: 'MasterCamp',
  MASTERCLASS: 'MasterClass',
} as const

export const PersonalizationEvents = {
  ACTIONS: {
    NEXT: 'Next',
  },
  CATEGORIES: {
    PERSONALIZATION_INPUT: 'personalization-input',
  },
  EVENTS: {
    PERSONALIZATION_CLICK: 'Personalization Click',
    PERSONALIZATION_STEP_COMPLETED: 'Personalization Step Completed',
    PERSONALIZATION_STEP_SKIPPED: 'Personalization Step Skipped',
  },
  PAGES: {
    PERSONALIZATION: 'personalization',
  },
}

export const AD_PARTNERS = [
  'Bing Ads',
  'Facebook CAPI',
  'Facebook Pixel',
  'Google AdWords New',
  'Google Tag Manager',
  'ShareASale',
  'TikTok',
]

export const LandingEvents = {
  PAGE_VIEWED: 'Page Viewed',
  NAVIGATION_CLICKED: 'Navigation Click',
  PAGE_CLICK: 'Landing Page Click',
  PRODUCT_VIEWED: 'Product Viewed',
  PURCHASE_CLICK: 'Purchase Click',
  // ROW EVENTS
  ROW_VIEWED: 'Row Viewed',
  ROW_SCROLLED: 'Row Scrolled',
  ROW_TILE_VIEWED: 'Row Tile Viewed',
  ROW_TILE_CLICKED: 'Row Tile Clicked',
  // CHECKOUT EVENTS
  CHECKOUT_CLICK: 'Checkout Click',
  PRODUCT_ADDED: 'Product Added',
  CART_EMAIL_CAPTURED: 'Cart Email Captured',
  CHECKOUT_STARTED: 'Checkout Started',
  ORDER_COMPLETED: 'Order Completed',
  CHECKOUT_OFFERING_NOT_AVAILABLE_VIEWED: 'Offering Not Available Modal Viewed',
}

export const TileClickEvents = {
  HOVER_PLAY: 'hover_over|play_icon',
  JACKPOT_LIST_ITEM: (contentLabel: string) => `jackpot_${contentLabel}|tile`,
  JACKPOT_THUMBNAIL: 'jackpot_class_main_thumbnail|tile',
  JACKPOT_THUMBNAIL_CTA: (ctaText: string) =>
    `jackpot_class_main_thumbnail|${ctaText}`,
  SEE_ALL: 'see_all',
  TILE: 'tile',
}
export type TileClickEvent =
  (typeof TileClickEvents)[keyof typeof TileClickEvents]
