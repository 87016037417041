import uaParser from 'ua-parser-js'
import Cookie from 'cookie-universal'
import { initGeoService } from '@mc/next-geo'
import { getAnonymousId } from '@mc/next-anonymous-id'

export const VALID_TEST_COOKIE_PATTERN = /^optly_[\w-]{2,14}?_test$/

export const getUserAttributes = (ctx, router) => {
  const { req, res } = ctx

  const { geo, region } = initGeoService(req, res, router)
  const ua = uaParser(req.headers['user-agent'])
  const userId = getAnonymousId(req, res)
  const cookies = Cookie(req, res)

  return {
    bogo_available: cookies.get('bogo_available') || false,
    business_package: false,
    country: geo,
    device: ua.device.type || 'desktop',
    free_trial_type: getFreeTrialType(req, cookies),
    is_test_user: getOptimizelyTestCookie(cookies),
    is_logged_in: cookies.get('_mcl') === 1,
    membership: cookies.get('user_membership') || 'FREE',
    guest_pass_feature_available:
      cookies.get('guest_pass_feature_available') || false,
    partner_subscription: false,
    platform_name: 'web',
    promo_code: getPromoCode(ctx, cookies),
    region,
    saml: false,
    subscribed_through_bulk_gift: false,
    experience: 'consumer',
    app_version_code: null,
    userId,
  }
}

const getFreeTrialType = (req, cookies) => {
  if (!!req.params?.referral || cookies.get('referral_token')) {
    return 'guest_pass'
  }
  return null
}

const getPromoCode = (ctx, cookies) =>
  ctx.query?.promo_id ||
  cookies.get('discount_slug') ||
  cookies.get('promo_code') ||
  null

// The cookie that the optimizely extension sets is based off of the domain. So rather than having folx tinker with
// their cookies, we can just check for the cookie set by the extension. The cookie the extension sets follows an
// "optly_[somthing]_test" pattern.
const getOptimizelyTestCookie = (cookies) =>
  Object.keys(cookies.getAll()).some((cookieName) =>
    cookieName.match(VALID_TEST_COOKIE_PATTERN),
  )
