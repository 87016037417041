import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { has } from 'lodash'

import { parseCategory } from './tracking/useLibraryEvents'

type CallPagesOptions =
  // Call with no extra UI opened
  | 'persona conversation'
  // Recommendations tile list opened
  | 'persona content recommendations page'
  // Library tile list opened
  | 'persona content page'
  // Recipe detail opened
  | 'persona content card'

interface CallAnalyticsContextType {
  conversationId?: string
  recommendationId?: string
  recommendationIdContents: string[]
  userTriggerMessageId: string
  currentPage: CallPagesOptions
  currentTab: 'library' | 'history' | null
  currentCategoryTab: string | null
  currentContentTitle: string | null
  currentContentUuid: string | null
  handleSetCallId: (id: string) => void
  handleSetAnalytics: (analytics: {
    [key: string]: string | number | string[] | null
  }) => void
  handleSetCurrentPage: (pageToSet: CallPagesOptions) => void
}

export const CallAnalyticsContext = createContext<CallAnalyticsContextType>({
  handleSetCallId: () => {},
  handleSetCurrentPage: () => {},
  handleSetAnalytics: () => {},
  recommendationIdContents: [],
  userTriggerMessageId: '',
  currentPage: 'persona conversation',
  currentTab: null,
  currentCategoryTab: null,
  currentContentTitle: null,
  currentContentUuid: null,
})

export const useCallAnalytics = () => {
  const CallAnalyticsContextInstance = useContext(CallAnalyticsContext)
  if (!CallAnalyticsContextInstance)
    throw Error(
      'useCallAnalytics can only be used inside an CallAnalyticsProvider',
    )
  return CallAnalyticsContextInstance
}

interface CallAnalyticsProviderProps {
  initialState: { conversationId?: string }
  children: React.ReactNode
}

export const CallAnalyticsProvider = ({
  initialState,
  children,
}: CallAnalyticsProviderProps) => {
  const [conversationId, setConversationId] = useState(
    initialState.conversationId || '',
  )
  const [currentPage, setCurrentPage] = useState<CallPagesOptions>(
    'persona conversation',
  )
  // TODO: recommendationId is not currently sent by BE
  const [recommendationId, setRecommendationId] = useState('')
  const [recommendationIdContents, setRecommendationIdContents] = useState<
    string[]
  >([])
  const [userTriggerMessageId, setUserTriggerMessageId] = useState('')
  const [currentTab, setCurrentTab] = useState<'library' | 'history' | null>(
    null,
  )
  const [currentCategoryTab, setCurrentCategoryTab] = useState<string | null>(
    null,
  )
  const [currentContentTitle, setCurrentContentTitle] = useState('')
  const [currentContentUuid, setCurrentContentUuid] = useState('')

  const handleSetCallId = (id: string) => {
    setConversationId(id)
  }

  const handleSetAnalyticsDynamic = useCallback(
    (analytics: { [key: string]: string | number | string[] | null }) => {
      if (has(analytics, 'callId')) handleSetCallId(analytics.callId as string)
      if (has(analytics, 'recommendationId'))
        setRecommendationId(analytics.recommendationId as string)
      if (has(analytics, 'recommendationIdContents'))
        setRecommendationIdContents(
          analytics.recommendationIdContents as string[],
        )
      if (has(analytics, 'userTriggerMessageId'))
        setUserTriggerMessageId(analytics.userTriggerMessageId as string)
      if (has(analytics, 'currentPage'))
        setCurrentPage(analytics.currentPage as CallPagesOptions)
      if (has(analytics, 'currentTab'))
        setCurrentTab(analytics.currentTab as 'history' | 'library' | null)
      if (has(analytics, 'currentCategoryTab')) {
        const parsedCategory = parseCategory(
          analytics.currentCategoryTab as string,
        )
        setCurrentCategoryTab(parsedCategory || null)
      }
      if (has(analytics, 'currentContentUuid'))
        setCurrentContentUuid(analytics.currentContentUuid as string)
      if (has(analytics, 'currentContentTitle'))
        setCurrentContentTitle(analytics.currentContentTitle as string)
    },
    [],
  )

  const handleSetCurrentPage = (pageToSet: CallPagesOptions) => {
    setCurrentPage(pageToSet)
  }

  const contextValue = useMemo(
    () => ({
      conversationId,
      recommendationId,
      recommendationIdContents,
      userTriggerMessageId,
      currentPage,
      currentTab,
      currentCategoryTab,
      currentContentTitle,
      currentContentUuid,
      handleSetCallId,
      handleSetAnalytics: handleSetAnalyticsDynamic,
      handleSetCurrentPage,
    }),
    [
      conversationId,
      recommendationId,
      recommendationIdContents,
      userTriggerMessageId,
      handleSetAnalyticsDynamic,
      currentPage,
      currentTab,
      currentCategoryTab,
      currentContentTitle,
      currentContentUuid,
    ],
  )

  return (
    <CallAnalyticsContext.Provider value={contextValue}>
      {children}
    </CallAnalyticsContext.Provider>
  )
}
