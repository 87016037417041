import newrelic from 'newrelic'
import type NewRelicBrowser from 'new-relic-browser'

declare global {
  interface Window {
    newrelic?: typeof NewRelicBrowser
  }
}

/**
 * A utility for sending error logs to New Relic that
 * works on the Next.js server or the browser.
 *
 * @param err The error instance to log
 * @param props Optional extra attributes to send to New Relic
 */
export const reportError = (err: unknown, props?: Record<string, string>) => {
  // eslint-disable-next-line no-console
  console.error(
    JSON.stringify({
      err: String(err),
      stack: err instanceof Error ? err.stack : undefined,
      props,
    }),
  )

  if (typeof window === 'undefined') {
    newrelic.noticeError(err, props)
  } else if (err instanceof Error || typeof err === 'string') {
    window.newrelic?.noticeError(err, props)
  }
}
