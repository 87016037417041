export const paths = {
  root: '/',
  // account management routes
  settings: '/settings',
  // Auth & checkout routes
  personaCustomAuth: (
    method: 'login' | 'signup' | 'forgot-password' | string,
  ) => `/${method}`,
  // checkout routes
  personaCheckoutRoot: '/checkout',
  personaCheckoutSignUp: '/checkout/signup',
  personaCheckoutMembership: '/checkout/membership',
  personaCheckoutPayment: '/checkout/payment',
  personaCheckoutComplete: '/checkout/success',
  // help resources & legal routes
  // TODO: Move legal, faq, footer routes here
  helpCenter: '/help-center',
  requestDataDeletion:
    'https://privacy.masterclass.com/policies?modal=select-subject',
  homepage: '/homepage',
  homepageCoaches: '/homepage/coaches',
  homepageRoleplay: '/homepage/coaching',
  homepageCoachingCategory: (category: 'workplace-communication') =>
    `/homepage/coaching/category/${category}`,
  masterclassCoreSettings: 'https://www.masterclass.com/account/edit',
  // aimentor (persona) routes
  personaCall: (instructorSlug = ':instructorSlug') =>
    `/aimentor/${instructorSlug}/call`,
  personaChat: (instructorSlug = ':instructorSlug', topicUuid: string = '') =>
    `/aimentor/${instructorSlug}/chat${topicUuid ? `?topic_uuid=${topicUuid}` : ''}`,
  personaLibrary: (instructorSlug = ':instructorSlug') =>
    `/library/${instructorSlug}`,
  personaRoleplayStart: (
    categorySlug = ':categorySlug',
    scenarioSlug = ':scenarioSlug',
  ) => `${baseRoleplaySetupPath(categorySlug, scenarioSlug)}/start`,
  personaRoleplaySetup: (
    categorySlug = ':categorySlug',
    scenarioSlug = ':scenarioSlug',
  ) => `${baseRoleplaySetupPath(categorySlug, scenarioSlug)}`,
}

export const isHomepagePath = (pathname: string) =>
  pathname.includes(paths.homepage)

const baseRoleplaySetupPath = (
  categorySlug = ':categorySlug',
  scenarioSlug = ':scenarioSlug',
) => `/roleplay/category/${categorySlug}/scenario/${scenarioSlug}`
