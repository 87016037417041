import React from 'react'
import { IconLogoDefault } from '@mc/design-system'
import { useTranslation, Trans, I18nextProvider } from '@mc/i18n'
import { paths } from '@mc/paths'
import { i18nInstance } from './i18n/i18nInstance'

import styles from './ErrorPage.module.scss'

type ErrorPageProps = {
  heading?: string
  subHeading?: string
  text?: string | React.ReactNode
}

const ErrorPageImpl = ({ heading, subHeading, text }: ErrorPageProps) => {
  const { t } = useTranslation('@mc/error-page')
  const handleClickRefresh = () => window.location.reload()

  return (
    <div
      className={`container d-flex justify-content-center align-items-center flex-column ${styles.container}`}
    >
      <IconLogoDefault size={10} className={styles.logo} />
      <h1 className='mc-text-h2 mc-mb-2'>{heading ?? t('heading')}</h1>
      <h2 className='mc-text-h5 mc-mb-2'>{subHeading ?? t('subHeading')}</h2>
      <div className={`row justify-content-center ${styles.textContainer}`}>
        <p className='mc-text-medium col-8'>
          {text ?? (
            <Trans i18nKey='help' ns='@mc/error-page'>
              <button className='mc-text--link' onClick={handleClickRefresh} />
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a className='mc-text--link' href={paths.helpCenter} />
            </Trans>
          )}
        </p>
      </div>
    </div>
  )
}

export const ErrorPage = (props: ErrorPageProps) => (
  <I18nextProvider i18n={i18nInstance} defaultNS='@mc/error-page'>
    <ErrorPageImpl {...props} />
  </I18nextProvider>
)
