import { getDataFromHeadTag } from '@mc/html-data-attr'

export type Variants = string

export type Experiments = Record<string, Variants | null | undefined>

export type ExperimentsVariables = Record<
  string,
  Record<string, object | string | boolean | number | null> | null
>

let experiments = getDataFromHeadTag('experiments') as Experiments

let experimentsVariables = getDataFromHeadTag(
  'experimentsVariables',
) as ExperimentsVariables

export const setExperiments = (props: Experiments) => {
  experiments = { ...props }
}

export const setExperimentsVariables = (props: ExperimentsVariables) => {
  experimentsVariables = { ...props }
}

export const getExperiments = () => ({ ...experiments })
export const getExperimentsVariables = () => ({ ...experimentsVariables })

export default getExperiments
