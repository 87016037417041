import { useEffect } from 'react'
import Cookie from 'cookie-universal'
import { ANONYMOUS_ID } from '@mc/next-anonymous-id'

type AnonymousIdProps = {
  ajsAnonId?: string | undefined | null
}

/**
 */
export const AnonymousId = ({ ajsAnonId }: AnonymousIdProps) => {
  // AnonymousId synchronization
  // https://masterclass-dev.atlassian.net/browse/PEN-3139

  // this first useEffect ensures that the cookie is set on the client
  // IMMEDIATELY. If not set, then no anonymous ID will be passed in with
  // `credentials: include` in a client-side fetch call. The client-side API
  // call to Rails will then return a response with a `Set-Cookie` header that
  // overwrites to a new anonymous ID and can cause inconsistent bucketing.
  useEffect(() => {
    if (ajsAnonId) {
      const cookies = Cookie()
      cookies.set(ANONYMOUS_ID, ajsAnonId)
    }
  }, [ajsAnonId])

  // set the anonymous ID in localStorage to ensure compliance with
  // any 3rd party scripts that rely on anonymous ID from there
  useEffect(() => {
    if (ajsAnonId) {
      const prev = localStorage?.getItem?.(ANONYMOUS_ID)
      if (prev !== ajsAnonId) {
        // 'AJS: Overwriting', prev, 'with', ajsAnonId
        localStorage?.setItem?.(ANONYMOUS_ID, ajsAnonId)
      }
    }
  }, [ajsAnonId])

  return null
}
