import { getDataFromHeadTag } from '@mc/html-data-attr'
import { pick } from 'lodash'
import { convertKeysToCamelCase, KeysToCamelCase } from '../../keysToCamelCase'

export interface EnterpriseSeatTokenProps {
  id: number
  license_id: number
  license_require_consent: boolean
  consent_given: boolean
}
// availableAuthTypes, bogoEligible, canUpgrade, gdprOptIn, slug
export interface UserProps {
  id: number
  available_auth_types: Array<{
    type: string
    active_annual_pass?: boolean
  }>
  bogo_eligible: boolean
  can_upgrade: boolean
  email: string
  gdpr_opt_in: boolean
  slug: string
  created_at: string | null
  updated_at: string | null
  permissions: {
    admin: boolean
    comments: boolean
  }
  privacy_settings: object | null
  address: string | null
  profile_photo_url: string | null
  first_name: string | null
  last_name: string | null
  last_ip: string | null
  needs_setup: boolean
  notifications_viewed_at: string | null
  employee: boolean
  trial_starts_at: string | null
  trial_ends_at: string | null
  gcid: string | null
  deleted_at: string | null
  role: string
  last_geo: unknown
  mature_content_enabled: boolean | null
  locale: string | null
  userleap_signup_sent_at: string | null
  locales_on: {
    [locale: string]:
      | {
          last_day: string
          first_day: string
        }
      | undefined
  }
  subtitle_enabled: boolean
  subtitle_locale: string | null
  canonical_id: string
  session_expired_at: string | null
  profile_id: number
  email_token: string
  enrolled_courses: string[]
  enrolled_courses_instructors:
    | {
        id: number
        name: string
        slug: string
        created_at: string
        updated_at: string
        first_name: string
        name_i18n: {
          [locale: string]: string | undefined
        }
        slug_i18n: {
          [locale: string]: string | undefined
        }
        first_name_i18n: {
          [locale: string]: string | undefined
        }
        bio: string | null
        alt_texts_i18n: {
          [locale: string]: string | undefined
        }
      }[][]
    | null

  active_annual_pass: boolean
  subscription_id: number
  mobile_app_install: boolean
  bogo_gift_token: string | null
  bogo_gift_expiration: string | null
  bogo_gift_expiration_raw: string | null
  project_plus_one_gift_token: string | null
  has_had_subscriptions: boolean
  has_credit_card: boolean
  braintree_paypal_email_of_latest_sub: string | null
  has_braintree_cc_sub: boolean
  sponsored_subscription_info: unknown
  organizations_administered: number[] | undefined
  has_active_afterpay_subscription: boolean
  gift_available_to_send: boolean
}

export interface UserInfoProps {
  id: number
  email: string
  trial_starts_at: string | null
  trial_ends_at: string | null

  connected_to_facebook: boolean
  user_state?: string

  project_plus_one_offer_eligible: boolean
  subscription: {
    updated_from_sap: boolean
    id: number
    status: string
    provider_type: string
    canceled_at: string | null
    created_at: string | null
    remaining_subscription_days: number
    expiration_date: string | null
    cancel_at_period_end: boolean
    originator_type: string
    is_monthly_pass: boolean
    canceled_on_paypal_dashboard: boolean | null
    purchase_country: string | null
    enterprise_business_user: boolean
    access_type: string
    current_subscription_cycle: number
    can_upgrade_to_consumer: boolean
    is_price_changes_experiment_subscription: boolean
  } | null
  partnerships: {
    partner_name?: string
    partner_display_name?: string
    partner_image?: string
    partner_url?: string
    partner_payment_gateway?: string
    reseller_sku_display_name?: string
    sku?: string
    sku_slug?: string
  } | null
  need_confirm_residence: boolean
  enterprise_admin: boolean
  enterprise_seat_token?: EnterpriseSeatTokenProps
}

export interface BaseAccount {
  loggedInMobileWebUser?: boolean
  subscribedState?: string
  bogoOfferEligible?: unknown
  enterpriseBusinessUser?: boolean
  requireConsent?: boolean
}

export type Account = KeysToCamelCase<BaseAccount & UserProps & UserInfoProps>

let account: Account | undefined

const initializeAccount = (): Account => {
  const userProps = getDataFromHeadTag('user') as UserProps
  const userInfoProps = getDataFromHeadTag('userInfo') as UserInfoProps
  const loggedInMobileWebUser = getDataFromHeadTag(
    'loggedInMobileWebUser',
  ) as boolean

  return {
    ...(userProps && convertKeysToCamelCase(userProps)),
    ...(userInfoProps && convertKeysToCamelCase(userInfoProps)),
    ...(userInfoProps && { subscribedState: userInfoProps.user_state }),
    loggedInMobileWebUser,
  }
}

export const getAccount = (): Account => {
  if (!account) {
    account = initializeAccount()
  }

  return { ...account }
}

// this is really only to bridge an anti-pattern.
// this logic should probably be closer to any API calls
export const updateAccount = (props: Partial<Account>) => {
  if (!account) {
    account = initializeAccount()
  }

  const accountAttributeWhitelist = [
    'id',
    'email',
    'matureContentEnabled',
    ...Object.keys(account),
  ]

  Object.assign(account, pick(props, accountAttributeWhitelist))
}

export const forceUpdateAccount = (props: Partial<Account>) => {
  account = { ...(props as Account) }
}

export const getAccountMembershipState = () => {
  if (!account) {
    account = initializeAccount()
  }

  const { subscribedState } = account

  return subscribedState
}
