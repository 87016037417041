type Level = 'log' | 'info' | 'warn' | 'error'

const _logger = (level: Level, message: string, props: unknown) => {
  let spacing
  if (process?.env?.ENV === 'development') {
    spacing = 2
  }

  const obj = { level, message }
  // eslint-disable-next-line no-console
  const log = console[level] || console.log
  try {
    log(JSON.stringify({ ...obj, props }, undefined, spacing))
  } catch (e) {
    log(
      JSON.stringify(
        { ...obj, props: 'props failed to be logged', error: String(e) },
        undefined,
        spacing,
      ),
    )
  }
}

export const logger = {
  log: (message: string, props?: unknown) => _logger('log', message, props),
  error: (message: string, props?: unknown) => _logger('error', message, props),
}

export const nullLogger = {
  log: () => {},
  error: () => {},
}
